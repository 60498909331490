/*global FB*/
import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, listAll, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import './App.css';

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [user, setUser] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {

    const firebaseConfig = {
      apiKey: "AIzaSyBNAjarhW-2_vjzMnFbGrLq2u1aDVXGfJg",
      authDomain: "firesteam-1a698.firebaseapp.com",
      databaseURL: "https://firesteam-1a698-default-rtdb.firebaseio.com",
      projectId: "firesteam-1a698",
      storageBucket: "firesteam-1a698.appspot.com",
      messagingSenderId: "69440478475",
      appId: "1:69440478475:web:58e3c2d74d1489ecdd160e",
      measurementId: "G-YNRJGZ4KFH"
    };


    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage);


    listAll(storageRef)
      .then((result) => {
        const promises = result.items.map((itemRef) => getDownloadURL(itemRef));
        Promise.all(promises)
          .then((urls) => {
            setImageUrls(urls);
          })
          .catch((error) => {
            console.error('Error downloading URLs:', error);
          });
      })
      .catch((error) => {
        console.error('Error listing images:', error);
      });


    const auth = getAuth();
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });


    loadFacebookSDK();
  }, []);


  const loadFacebookSDK = () => {
    window.fbAsyncInit = function() {
      FB.init({
        xfbml            : true,
        version          : 'v19.0'
      });
    };
    
    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    const body = document.body;
    body.classList.toggle('dark-mode');
  };

  const handleGoogleSignIn = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        setUser(result.user);
      })
      .catch((error) => {
        console.error('Google Sign-In failed:', error);
      });
  };

  const handleSignOut = () => {
    const auth = getAuth();
    auth.signOut()
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error('Sign-out failed:', error);
      });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      console.error('.');
      return;
    }
    
    const image = new Image();
    image.src = URL.createObjectURL(selectedFile);

    image.onload = () => {
      const canvas = document.createElement('canvas');
      let width = image.width;
      let height = image.height;

      if (width > 500 || height > 640) {
        const ratio = Math.min(500 / width, 640 / height);
        width *= ratio;
        height *= ratio;
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, width, height);

      canvas.toBlob((blob) => {
        const pngFile = new File([blob], "image.png", { type: "image/png" });

        const storage = getStorage();
        const storageRef = ref(storage, pngFile.name);
        uploadBytes(storageRef, pngFile)
          .then((snapshot) => {
            console.log('Photo Added');
            setSelectedFile(null);
            window.location.reload();
          })
          .catch((error) => {
            console.error('Error uploading file:', error);
          });
      }, 'image/png');
    };
  };

    const handleDelete = (fileUrl) => {
        const storage = getStorage();
        const fileRef = ref(storage, fileUrl);

        deleteObject(fileRef)
          .then(() => {
            console.log('File deleted successfully');
            setImageUrls(imageUrls.filter((url) => url !== fileUrl)); // Remove the deleted file from the state
          })
          .catch((error) => {
            console.error('Error deleting file:', error);
          });
      };

  const copyToClipboard = () => {
    const textToCopy = "https://ilostmyipad.web.app";
    
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          alert("URL copied to clipboard: " + textToCopy);
        })
        .catch((error) => {
          console.error('Failed to copy to clipboard:', error);
        });
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      alert("URL copied to clipboard: " + textToCopy);
    }
  };

  const shareContent = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Photo Stream by JR',
          text: 'JRs Photo stream',
          url: 'https://junior.jessejesse.com',
        });
      } else {
        throw new Error('Web Share API is not supported');
      }
    } catch (error) {
      console.error('Error sharing content:', error);
    }
  };

    return (
        <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
          <header>
            <code>
              <a href="https://ilostmyipad.web.app" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none'}}>
                <pre>&#10084;&nbsp;ilostmyipad.web.app</pre>
              </a>
            </code>
            <nav>
              {user ? (
                <>
                  <input type="file" onChange={handleFileChange} />
                  <button onClick={handleUpload}>Upload</button>
                  <button onClick={handleSignOut}>Sign Out</button>
                </>
              ) : (
                <button onClick={handleGoogleSignIn}>Google</button>
              )}
              <button onClick={toggleDarkMode} id="toggleButton">{darkMode ? 'Light' : 'Dark'}</button>
              <button onClick={copyToClipboard}>Copy</button>
              <button onClick={shareContent}>Share</button>
            </nav>
          </header>
          <div className="carousel-container">
            <div className="carousel">
              {imageUrls.map((url, index) => (
                <div key={index} className="slide">
                  <img src={url} alt={`Image ${index}`} />
                  {user && <button onClick={() => handleDelete(url)}>Delete</button>} {/* Button to delete the image */}
                </div>
              ))}
            </div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
          <div className="facebook-buttons">
            <div className="fb-like" data-href="https://jj-bucket.vercel.app/" data-width="" data-layout="" data-action="" data-size="" data-share="true"></div>
          </div>
          <footer>
            <code>
              <a href="https://Junior.jessejesse.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none'}}>
                <pre>Junior.JesseJesse.com&nbsp;&#10084;&nbsp;2024</pre>
              </a>
            </code>
          </footer>
        </div>
      );
    };

    export default App;
